//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PlataformaPage',
  data() {
    return {
      creation: [
        {
          name: 'fórum de cultura CG',
          link: 'http://forumdeculturacg.blogspot.com/'
        },
        {
          name: 'nomads.usp',
          link: 'http://www.nomads.usp.br/site/'
        },
        {
          name: 'algo+ritmo.ufms',
          link: 'https://algoritmoufms.wixsite.com/algoritmo'
        },
        {
          name: 'coletivo.labor',
          link: 'http://coletivolabor.com.br/'
        },
      ],
      team: [
        {
          name: 'Juliana',
          lastName: 'Trujillo',
          category: 'coordenadora',
          color: '#C95B40',
        },
        {
          name: 'Marcelo',
          lastName: 'Tramontano',
          category: 'orientador',
          color: '#AD3B3B',
        },
        {
          name: 'Cintia',
          lastName: 'Fontes',
          category: 'desenvolvedora de software',
          color: '#AD3B3B',
        },
        {
          name: 'Vanessa',
          lastName: 'Me Tonini',
          category: 'desenvolvedora de software',
          color: '#AD3B3B',
        },
        {
          name: 'Aarão',
          lastName: 'Melo Lopes',
          category: 'desenvolvedor de software',
          color: '#AD3B3B',
        },
        {
          name: 'Henrique',
          lastName: 'Leonel',
          category: 'desenvolvedor de software',
          color: '#DBB753',
        },
        {
          name: 'Max',
          lastName: 'Hiroito',
          category: 'desenvolvedor de software',
          color: '#4692C1',
        },
        {
          name: 'Eduardo',
          lastName: 'Azevedo',
          category: 'interface gráfica',
          color: '#AD3B3B',
        },
      ],
      colaborators: [
        {
          name: 'André Vilela',
        },
        {
          name: 'Franciella Cavalheri',
        },
        {
          name: 'Gilfranco Alves',
        },
        {
          name: 'Kenzo Minata',
        },
        {
          name: 'Thaís Pompeo',
        },
        {
          name: 'Paulo Domingos',
        },
        {
          name: 'Eliane Fraulob',
        },
        {
          name: 'Juliano Pita',
        },
        {
          name: 'Victor Silva',
        },
        {
          name: 'Pedro Teixeira',
        },
        {
          name: 'Mario Vallejo',
        },
        {
          name: 'Rafael Alves'
        },
        {
          name: 'Guilherme Jardim',
        },
        {
          name: 'Gabriel Matias',
        },
        {
          name: 'Geovanna Santiago',
        }
      ]
    };
  },
};
